import {getLocaleFromPath} from '~/helpers/getLocaleFromPath';
import {useUserStore} from '~/modules/User/user.store';
import Logger from "~/helpers/logger";

export default defineNuxtRouteMiddleware((to) => {
  const locale = getLocaleFromPath(to.fullPath);
  const user = useUserStore();
  const isLoggedIn = user.isLoggedIn;
  const logger = Logger.getInstance();
  // const router = useRouter();

  // Старый код изменен разработчиком Savva (savvasiry@gmail.com)
  // switch (true) {
  //   case to.path.includes('design-studio/'):
  //     {
  //       if (!isLoggedIn) {
  //         router.push(`/${locale}/design-studio`);
  //       }
  //     }
  //     break;
  //   default:
  //     break;
  // }

  // Новый код
  if (to.path.includes('design-studio/') && !isLoggedIn) {
    logger.info(`[AUTH] User is not logged in. Redirecting to /design-studio.`);
    return navigateTo(`/${locale}/design-studio`);
  }

  logger.info(`[AUTH] User was successfully authorized.`);
});
